import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "react-intl"


const Projects = props => {
  const intl = useIntl();

  return (
    <>
      <Seo title={intl.formatMessage({id: "projects_title"})}/>
      <Layout>
        <h6> ‎ </h6>
        <h1>{intl.formatMessage({id: "projects_text0"})}</h1>
        <p>{intl.formatMessage({id: "projects_text1"})}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({id: "projects_text2"})} <i>{intl.formatMessage({id: "projects_text3"})}</i>{intl.formatMessage({id: "projects_text4"})}<i>{intl.formatMessage({id: "projects_text5"})}</i>{intl.formatMessage({id: "projects_text6"})}<i>{intl.formatMessage({id: "projects_text7"})}</i></p>
        <p>{intl.formatMessage({id: "projects_text8"})}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({id: "projects_text9"})} <i>{intl.formatMessage({id: "projects_text10"})}</i>{intl.formatMessage({id: "projects_text11"})}</p>
        <p>{intl.formatMessage({id: "projects_text12"})}<i>{intl.formatMessage({id: "projects_text13"})}</i>{intl.formatMessage({id: "projects_text14"})}<i>{intl.formatMessage({id: "projects_text15"})}</i></p>
        <p>{intl.formatMessage({id: "projects_text25"})}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({id: "projects_text18"})}<i>{intl.formatMessage({id: "projects_text19"})}</i>{intl.formatMessage({id: "projects_text20"})}</p>
        <p>{intl.formatMessage({id: "projects_text21"})}<i>{intl.formatMessage({id: "projects_text22"})}</i>{intl.formatMessage({id: "projects_text23"})}</p>
        <h6> ‎ </h6>
      </Layout>
    </>
  )
}

export default Projects
